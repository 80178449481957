import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Home } from './components/Home';
import { Layout } from './components/Layout';
import { Booking } from './components/Booking';
import { Cancelation } from './components/Cancelation';
import { Rules } from './components/Rules';
import { Nav } from './components/Nav';
import './styles/style.scss';

export default function App() {
  //global media state
  const [media, setMedia] = useState('');

  let scrollpos = window.scrollY;

  function add_class_on_scroll() {
    title.classList.add('backgroundTitle');
  }

  function remove_class_on_scroll() {
    title.classList.remove('backgroundTitle');
  }

  $(window).on('scroll', function (i) {
    let contentpos = document.getElementById('content').getBoundingClientRect()
      .top;
    let title = document.getElementById('title');
    fadeBanner();

    scrollpos = window.scrollY - contentpos * 1.25;

    if (scrollpos > contentpos) {
      add_class_on_scroll();
    } else {
      remove_class_on_scroll();
    }
    // console.log('SCROLLLLLLLLLL', scrollpos);
  });

  function fadeBanner() {
    let fadeVar = Math.max(0.01, (100 - $(window).scrollTop() / 0.9) / 100);
    // let marginVar = Math.max(-60, $(window).scrollTop() * -1);

    $('.bannerImg').css({
      opacity: fadeVar
      // marginTop: marginVar
    });
  }

  //Determine what route user is currently viewing

  return (
    <>
      <div className="bannerContainer">
        <BrowserRouter>
          <Nav />
          {/* <img className="bannerImg" src="MojaveDesertLarge.jpg" /> */}
          <img className="bannerImg" src="panoramaDesert.jpg" />
          <div id="title" className="bannerTitle">
            Book a Meeting Room
          </div>

          <div id="content">
            <Switch>
              <Route path="/Rules">
                <Rules />
              </Route>
              <Route path="/Cancelation">
                <Cancelation />
              </Route>
              <Route path="/Booking">
                <Booking media={media} setMedia={setMedia} />
              </Route>
              <Route path="/Layout">
                <Layout media={media} setMedia={setMedia} />
              </Route>
              <Route path="/">
                <Home media={media} setMedia={setMedia} />
              </Route>
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    </>
  );
}

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
