import React from 'react';

export function ContactForm({
  input, inputReminder, required, onChange, onCancel, onSubmit
}) {
  return (
    <>
      <div className="formTitle">Contact</div>
      <div className="form">
        <label htmlFor="name">Name:</label>
        <input
          id="name"
          name="name"
          type="text"
          placeholder="Your name"
          value={input.name}
          onChange={onChange}
          required={required.includes('name') ? true : false}
        />
        <label htmlFor="email">Email:</label>
        <input
          id="email"
          name="email"
          type="text"
          placeholder="Your email"
          value={input.email}
          onChange={onChange}
          required={required.includes('email') ? true : false}
        />
        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          name="message"
          placeholder="Enter your message here"
          value={input.message}
          onChange={onChange}
          rows="3"
          required={required.includes('message') ? true : false}
        ></textarea>
        <div className="formBtnContainer">
          <div className="left">
            <button
              id="submit"
              className="formButton"
              onClick={onSubmit}
            >
              Submit
              <div id="submitArrow" className="arrowSign">
                ✔
              </div>
            </button>
          </div>

          <div className="right">
            <button
              id="cancel"
              className="formButton"
              onClick={onCancel}
            >
              Cancel
              <div id="cancelArrow" className="arrowSign">
                ✖
              </div>
            </button>
          </div>
        </div>
      </div>

      <p id={inputReminder ? 'redText' : null}>
        {inputReminder
          ? 'Please fill out the form entirely before submitting'
          : 'Click Submit to send your message or Cancel to clear the form'}
      </p>
    </>
  );
}
