import React from 'react';
import { PricingOptions } from './PricingOptions';

export function BookingInstruct({ rulesTitle, cancelTitle }) {
  return (
    <div className="textBackground">
      <p>
        <b>Pricing per Room:</b>
        <PricingOptions />
      </p>
      <p>
        <b>Policies:</b>
        <ul>
          <li>
            <strong>Before booking your reservation</strong>, be sure to read
            the <em>{rulesTitle}</em> and information on <em>{cancelTitle}</em>
            {' '} located in the dropdown cards below.
          </li>
        </ul>
      </p>
      <p>
        <b>Creating Reservations:</b>
        <ul>
          <li>
            Once you've settled on a date and room for your reservation, use
            the Skedda interface to the right: click on the {' '}
            <b>green add button</b> in the lower right-hand corner to
            initiate booking.  (Clicking on a time slot will not work unless
            you pick the top slot.)
          </li>
        </ul>
      </p>
      <p>
        <b>Frequently Asked Questions:</b>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.skedda.com/en/articles/105724-creating-bookings-as-a-regular-user"
            >
              Creating bookings as a regular user
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.skedda.com/en/articles/453529-i-never-set-a-password-how-do-i-login"
            >
              I never set a password...how do I login?
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.skedda.com/en/articles/105768-what-payment-gateways-do-you-support"
            >
              What payment gateways do you support?
            </a>
          </li>
        </ul>
      </p>
    </div>
  );
}
