import React from 'react';

export function Location() {
  return (
    <div className="locationContainer">
      <div className="locationCardContainer">
        <img src="DuneSmall.jpg" />
        <div className="locationCard">
          <div className="roomName"> We Are Located At: </div>
          <p>
            Ridgecrest Training Center <br />
            330 E Ridgecrest Blvd <br />
            Suite B: Training Rooms #1, #2 <br />
            Ridgecrest, CA 93555 <br />
            <br />
            Ridgecrest Conference Center <br />
            350 E Ridgecrest Blvd <br />
            Suites 205, 206 <br />
            Ridgecrest, CA 93555
          </p>
        </div>
      </div>
      {/* Location: Google Maps Shared/Embedded Map: */}
      {/* Training Center - 330 E Ridgecrest Blvd */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.1923354073083!2d-117.66716988431314!3d35.6229846802079!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c16cb8eef5a0b7%3A0xb722c19da8d61ecb!2s330%20E%20Ridgecrest%20Blvd%2C%20Ridgecrest%2C%20CA%2093555!5e0!3m2!1sen!2sus!4v1636690715214!5m2!1sen!2sus"
        width="600"
        height="450"
        style={{ border: '0px', width: '46%' }}
        allowFullScreen=""
        loading="lazy"
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
      {/* Conference Center - 350 E Ridgecrest Blvd */}
      {/* <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.1907470888586!2d-117.66671158445011!3d35.62302384076875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c16cb8e4001929%3A0xa2e0c0565b9b73e0!2s350%20E%20Ridgecrest%20Blvd%2C%20Ridgecrest%2C%20CA%2093555!5e0!3m2!1sen!2sus!4v1592524504301!5m2!1sen!2sus"
        width="600"
        height="450"
        frameBorder="0"
        style={{ border: '0px', width: '46%' }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe> */}
    </div>
  );
}
