import React from 'react';

export function FeaturesDetail() {
  return (
    <div className="detailsContainer">

      <div className="roomCard">
        <div className="duneImg">
          {' '}
          <img src="duneRipples.jpg" />
        </div>
        <div className="detailsTitle"> Features </div>
        <ul className="textBackground">
          <li>Conference Table</li>
          <li>Office Desk</li>
          <li>Chairs</li>
        </ul>
      </div>

      <div className="roomCard">
        <div className="duneImg">
          {' '}
          <img src="duneRipples.jpg" />
        </div>
        <div className="detailsTitle"> Amenities </div>
        <ul className="textBackground">
          <li>Internet (Wifi/Ethernet)</li>
          <li>Restrooms</li>
          <li>Parking</li>
        </ul>
      </div>

    </div>
  );
}
