import React from 'react';

export function CardDropdown({
  accordian, openAccordian, cardProperties, contentComponent
}) {
  return (
    <div
      className={accordian[cardProperties.accordianName] ? 'openButton' : 'closedButton'}
      onClick={() => {
        openAccordian(cardProperties.accordianName);
      }}
    >
      <div className="policyTitle">{cardProperties.policyTitle}</div>

      {contentComponent}

      <div className="symbolBox">
        <div
          id={cardProperties.cssIds.horizontal}
          className={
            accordian[cardProperties.accordianName]
              ? `lineH ${cardProperties.cssIds.minusAnimate}`
              : `lineH ${cardProperties.cssIds.plusAnimate}`
          }
        />
        <div className="lineV" />
      </div>
    </div>
  );
}
