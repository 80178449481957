import React from 'react';
import { IntroBase } from './IntroBase';

export function IntroForTablet({ handleClick }) {
  return (
    <div className="officeImages">
      <img id="office" src="officeBlurred.jpg" />
      <div className="provideTitle">Get Away From The Office</div>
      <IntroBase handleClick={handleClick} />
    </div>
  );
}
