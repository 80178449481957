import React from 'react';
import { PricingOptions } from './PricingOptions';
import { getVariableText } from '../helper/variableText';

export function BookingRules({ page }) {
  return (
    <div className="textBackground">
      <p>
        <b>Pricing and Timeframes:</b>
        <br />
        Booking reservations must be made 24 hours in advance of the desired
        reservation time.
        <br />
        Rooms are available 7 days per week and you may make reservations in
        increments of:
        <PricingOptions />
      </p>
      <p>
        If you wish to reserve a room for a <strong>week</strong> or longer,
        please use the contact form and instructions {getVariableText(page)},
        particularly the instructions under "Long-term Rental".
      </p>
      <p>
        <b>Before Leaving:</b>
        <br />
        To prepare the space for the next group please:
        <br />
        Put the chairs back, clear off the table, and place all used amenities
        back in their proper places; restored to their initial state.
      </p>
      <p>
        <strong>*</strong> For reservations lasting more than a day, cleaning
        services can be provided on inquiry.
      </p>
      {/* <p>
        <b>Capcity Limits:</b>
        <br />
        For your safety, the number of people allowed in—
        <br /> Suite 205 is 20 people &emsp; and &emsp; Suite 206 is 8 people
      </p> */}
    </div>
  );
}
