import React from 'react';

export function ContactInstruct() {
  return (
    <div className="textBackground">
      <p>
        Please fill out the contact form on the right according to your concern.
        <br />
        All submissions will be attended to between the hours of{' '}
        <strong>7 a.m.</strong> and <strong>7 p.m.</strong>
      </p>
      <p>
        <b>Always:</b>
      </p>
      <ul>
        <li>
          Fill out the form entirely.
        </li>
        <li>
          If you have a standing reservation, use the name and email address
          you used when booking your reservation.
        </li>
      </ul>
      <p>
        <b>Help Edit/Cancel:</b>
      </p>
      <ul>
        <li>
          In the first line of your message write "Help Edit Reservation" or
          "Help Cancel Reservation".
        </li>
        <li>
          In your message, be sure to indicate the <strong>room</strong> and
          {' '} <strong>time</strong> you originally reserved.
        </li>
        <li>
          If you need help with editing, list what <strong>day(s)</strong>{' '}
          you'd like the reservation to be changed to.
        </li>
      </ul>
      <p>
        <b>Long-term Rental:</b>
      </p>
      <ul>
        <li>
          In the first line of your message write "Long-term Rental".
        </li>
        <li>
          Clearly list the days you'd like this reservation to{' '}
          <strong>start</strong> and <strong>end</strong>, as well as
          which <strong>room</strong> you're interested in.
        </li>
      </ul>
    </div>
  );
}
