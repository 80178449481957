import React from 'react';
import { IntroBase } from './IntroBase';

export function IntroForNotTablet({ handleClick }) {
  return (
    <div className="officeImages">
      <div className="provideTitle">
        Get Away <br />
        From The Office
      </div>
      <IntroBase handleClick={handleClick} />

      <a>
        <img id="office" src="officeBlurred.jpg" />
      </a>
      <a>
        <div id="affordable" className="animatedBackground">
          <h1>Affordable</h1>
        </div>
        <div id="convenient" className="animatedBackground">
          <h1>Convenient</h1>
        </div>
        <div id="direct" className="animatedBackground">
          <h1>Direct&nbsp;Booking</h1>
        </div>
        <img id="people" src="officeWorkers.jpg" />
     </a>
   </div>
  );
}
