import axios from 'axios';
import React, { useState } from 'react';
import { Loader } from './content-for-booking/Loader';
import { Footer } from './Footer';
import { BookingInstruct } from './content-for-booking/BookingInstruct';
import { CardDropdown } from './content-for-booking/CardDropdown';
import { BookingRules } from './content-for-booking/BookingRules';
import { EditCancelInstruct } from './content-for-cancelation/EditCancelInstruct';
import { ContactInstruct } from './content-for-booking/ContactInstruct';
import { ContactForm } from './content-for-booking/ContactForm';
import '../styles/style.scss';
//import util from 'util';

const cardPropsRules = {
  accordianName: 'rules',
  policyTitle: 'Booking Rules',
  cssIds: {
    horizontal:   'rulesHorizontal',
    minusAnimate: 'rulesMinusAnimate',
    plusAnimate:  'rulesPlusAnimate',
  },
}
const cardPropsCancel = {
  accordianName: 'cancel',
  policyTitle: 'Changes or Cancelations',
  cssIds: {
    horizontal:   'cancelHorizontal',
    minusAnimate: 'cancelMinusAnimate',
    plusAnimate:  'cancelPlusAnimate',
  },
}


export function Booking({ media, setMedia }) {
  const [accordian, setAccordian] = useState({ cancel: false, rules: false });
  const [showLoader, setShowLoader] = useState(false);
  const [input, setInput] = useState({ name: '', email: '', message: '' });
  const [required, setRequired] = useState([]);
  const [inputReminder, setInputReminder] = useState(false);
  const [refreshForm, setRefreshForm] = useState(false);

  const onChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setInput(input => ({ ...input, [key]: value }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const inputArray = Object.values(input);
    if (inputArray.includes('')) {
      // If there's a blank input, remind user to completely fill form.
      let requiredArray = [...required];
      Object.keys(input).map(function (key, index) {
        if (input[key] === '') requiredArray.push(key);
      });
      setRequired(requiredArray);
      setInputReminder(true);
    } else {
      // Otherwise proceed to send message.
      setShowLoader(true);
      //console.log('Input:');
      //console.log(util.inspect(input, false, null, true /* enable colors */))
      setTimeout(() => {
        axios
          .post('/contact', input)
          .then(response => {
            const refreshInput = { name: '', email: '', message: '' };
            setInput(refreshInput);
            setRefreshForm(true);
            setRequired([]);

            //console.log('Response:');
            //console.log(util.inspect(response, false, null, true /* enable colors */))
            if (response.data.sendStatus === 'fail') {
              throw new Error(response.data.message)
            }
          })
          .catch(error => {
            //console.log('Error:');
            //console.log(util.inspect(error, false, null, true /* enable colors */))
            alert('Something went wrong! Refresh and try again.');
          });
      }, 3000);
    }
  };

  const onCancel = (e) => {
    e.preventDefault();
    const refreshInput = { name: '', email: '', message: '' };
    setInput(refreshInput);
    setInputReminder(false);
    setRequired([]);
  };

  //console.log('Required', required);

  const openAccordian = (id) => {
    let newObj = {};
    if (id === 'cancel') {
      if (!accordian.cancel) {
        newObj.cancel = true;
      } else {
        newObj.cancel = false;
      }
    } else if (id === 'rules') {
      if (!accordian.rules) {
        newObj.rules = true;
      } else {
        newObj.rules = false;
      }
    } else {
      throw new Error('openAccordian id input set to improper value.');
    }
    setAccordian(newObj);
  };

  return (
    <>
      <div className="contentContainer" style={{ zIndex: 1 }}>

        <div className="bookingContainer">
          <div id="bookingInstructions" className="instructions">
            <div className="header"> Booking Instructions </div>
            <BookingInstruct
              rulesTitle={cardPropsRules.policyTitle}
              cancelTitle={cardPropsCancel.policyTitle}
            />
          </div>
          <div className="bookingImg">
            <iframe
              className="booking-iframe"
              src="https://theqmrgroup.skedda.com/booking?embedded=true"
              style={{ width: '100%', height: '681px' }}
              //style={{ width: '100%', height: '100%', overflow: 'scroll' }}
              //style={{ width: '200%', height: '100%', overflow: 'scroll' }}
              //style={{ width: '200%', height: '100%', overflowX: 'scroll' }}
              //style={{ width: '200%', height: '100%' }}
            ></iframe>
            {/* <img src="office-desk.jpg" />
            <button
              className="bookingButton"
              onClick={console.log('Button Clicked')}
            >
              Book
              <div className="bookingArrow">➠</div>
            </button> */}
          </div>
        </div>

        <div className="centeredContainer">
          <div
            id="policyContainer"
            className={
              accordian.cancel || accordian.rules
                ? 'policyContainerOpen'
                : 'policyContainerClosed'
            }
          >
            <CardDropdown
              accordian={accordian}
              openAccordian={openAccordian}
              cardProperties={cardPropsRules}
              contentComponent={<BookingRules page='Booking' />}
            />
            <CardDropdown
              accordian={accordian}
              openAccordian={openAccordian}
              cardProperties={cardPropsCancel}
              contentComponent={<EditCancelInstruct page='Booking' />}
            />
          </div>
        </div>

        <div id="childBeforeFooter" className="contactContainer">
          <div id="contactInstructions" className="instructions">
            <div className="header"> Contact Instructions </div>
            <ContactInstruct />
          </div>

          <div className="formContainer">
            {showLoader ? (
              <Loader refreshForm={refreshForm} />
            ) : (
              <ContactForm
                input={input}
                inputReminder={inputReminder}
                required={required}
                onChange={onChange}
                onCancel={onCancel}
                onSubmit={onSubmit}
              />
            )}
            <div />
          </div>
        </div>

      </div>

      <Footer media={media} setMedia={setMedia} />
    </>
  );
}
