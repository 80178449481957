import React from 'react';
import { EditCancelInstruct } from './content-for-cancelation/EditCancelInstruct';
import { Footer } from './Footer';

export function Cancelation() {
  return (
    <>
      <div className="contentContainer">
        <div id="legalTerms">
          <div className="openButton">
            <div className="policyTitle">Cancelation Policy</div>
            <EditCancelInstruct page="Cancelation" />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
