import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export function Nav() {
  let location = useLocation();
  console.log('Current Path', location.pathname);

  const handleClick = (path) => {
    const currentLocation = location.pathname;
    if (currentLocation === path) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="stickyContainer">
      <div className="logoContainer">
        <img src="qmrLogo.jpg" />
      </div>
      <div className="menuContainer">
        <Link to="/">
          <div
            onClick={() => {
              handleClick('/');
            }}
            className={
              location.pathname === '/' ? 'selectedMenu' : 'menuButton'
            }
          >
            Home
          </div>
        </Link>
        <Link to="/Layout">
          <div
            onClick={() => {
              handleClick('/Layout');
            }}
            className={
              location.pathname === '/Layout' ? 'selectedMenu' : 'menuButton'
            }
          >
            Layout
          </div>
        </Link>
        <Link to="/Booking">
          <div
            onClick={() => {
              handleClick('/Booking');
            }}
            className={
              location.pathname === '/Booking' ? 'selectedMenu' : 'menuButton'
            }
          >
            Booking
          </div>
        </Link>
      </div>
    </div>
  );
}
