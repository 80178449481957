export function getVariableText(page) {

  let variableText;

  switch (page) {
    case 'Booking':
      variableText = 'below';
      break;
    case 'Rules':
      variableText = 'on the bottom of the Booking page';
      break;
    case 'Cancelation':
      variableText = 'on the bottom of the Booking page';
      break;
    default:
      throw new Error('Variable page has an unrecognized value.')
  }

  return variableText;
}
