import React from 'react';

export function PricingOptions() {
  return (
    <ul>
      <li>$600 &ndash; full day (12 hours)</li>
      <li>$2500 &ndash; week (5 consecutive days)</li>
    </ul>
  );
}
