import React from 'react';
import { IntroForTablet } from './content-for-home/IntroForTablet';
import { IntroForNotTablet } from './content-for-home/IntroForNotTablet';
import { FeaturesMarketing } from './content-for-home/FeaturesMarketing';
import { FeaturesDetail } from './content-for-home/FeaturesDetail';
import { Location } from './content-for-home/Location';
import { Footer } from './Footer';
//import 'jquery';


export function Home({ media, setMedia }) {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  //Text Animation

  // $(document).ready(() => {
  //   let target = $('.officeImagesContainer').offset().top;
  //   // timeout = null;
  //   let runClose = false;
  //
  //   $(window).scroll(function () {
  //     // console.log('window scrollTop', Math.ceil($(window).scrollTop()));
  //     // console.log('target', Math.ceil(target));
  //     // if (!timeout) {
  //     //   timeout = setTimeout(function () {
  //     //     clearTimeout(timeout);
  //     //     timeout = null;
  //     if (Math.ceil($(window).scrollTop()) >= Math.ceil(target) - 216) {
  //       $('#officeImagesContainer').addClass('openDrawer');
  //       runClose = true;
  //       $('#extraPaddingBottom').css('padding-bottom', '40px');
  //       $('#extraMarginTop').css('margin-top', '20px');
  //     }
  //
  //     if (runClose && $(window).scrollTop() === 0) {
  //       // console.log('CLOSINGGGGGG');
  //       $('#officeImagesContainer').animate({ height: '0px' }, 300);
  //       $('#officeImagesContainer').removeClass('openDrawer');
  //       $('#extraPaddingBottom').css('padding-bottom', '0px');
  //       $('#extraMarginTop').css('margin-top', '0px');
  //     }
  //     //   }, 250);
  //     //  }
  //   });
  // });

  //TO DO:
  //At 899px - Get rid of one office image (rework layout of animated image)
  return (
    <>
      <div className="contentContainer">

        <div id="extraPaddingBottom" className="centeredContainer">
          <div className="header"> What We Provide </div>
          {/* Intro 'Book Now' Section: */}
          <div id="officeImagesContainer" className="officeImagesContainer">
            {media === 'tablet' ? (
              <IntroForTablet handleClick={handleClick} />
            ) : (
              <IntroForNotTablet handleClick={handleClick} />
            )}
          </div>
        </div>

        <FeaturesMarketing />

        <div className="centeredContainer">
          <div id="rentalDetails" className="header"> Rental Details </div>
          <FeaturesDetail />
        </div>

        <div id="childBeforeFooter" className="centeredContainer">
          <div className="header"> Find Us </div>
          <Location />
        </div>

      </div>

      <Footer media={media} setMedia={setMedia} />
    </>
  );
}
