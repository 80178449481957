import React from 'react';
import { Footer } from './Footer';
import '../styles/style.scss';

export function Layout({ media, setMedia }) {
  return (
    <>
      <div className="contentContainer">

        <div className="centeredContainer">
          <div className="header"> Layout </div>
          <p className="textBackground">
            The Ridgecrest Training &amp; Conference Center consists of two
            buildings: the Training Center (at 330 E Ridgecrest Blvd) and the
            Conference Center (at 350 E Ridgecrest Blvd).  Currently, each
            building has two rooms available for booking, for a total of four
            available rooms.
          </p>
        </div>

        <div className="centeredContainer">
          <div className="subheader">Training Center</div>
          <p className="textBackground">
            330 E Ridgecrest Blvd (Floor 1) - Suite B
          </p>
          <div className="keyContainer">
            <div className="key" />
            <div className="label">
              <div className="label-name">
                Training Room #1
              </div>
              <div className="label-detail">
                (36&prime; &times; 12&prime; = 432 sq ft)
              </div>
            </div>
            <div className="key" />
            <div className="label">
              <div className="label-name">
                Training Room #2
              </div>
              <div className="label-detail">
                (22&prime; &times; 12&prime; = 264 sq ft)
              </div>
            </div>
            <div className="key" />
            <div className="label">
              <div className="label-name">
                Restrooms
              </div>
            </div>
          </div>
          <div className="layoutWrap">
            <img
              className="layoutImg"
              src="building330Layout.png"
            />
          </div>
        </div>

        <div className="centeredContainer">
          <div className="subheader"> Conference Center </div>
          <p className="textBackground">
            350 E Ridgecrest Blvd (Floor 2)
          </p>
          <div className="keyContainer">
            <div className="key" />
            <div className="label">
              <div className="label-name">
                Suite 205
              </div>
              <div className="label-detail">
                (1,000 sq ft)
              </div>
            </div>
            <div className="key" />
            <div className="label">
              <div className="label-name">
                Suite 206
              </div>
              <div className="label-detail">
                (500 sq ft)
              </div>
            </div>
            <div className="key" />
            <div className="label">
              <div className="label-name">
                Restrooms
              </div>
            </div>
          </div>
          <div className="layoutWrap">
            <img
              id="childBeforeFooter"
              className="layoutImg"
              src="building350Layout.png"
            />
          </div>
        </div>

      </div>

      <Footer media={media} setMedia={setMedia} />
    </>
  );
}
