import React from 'react';
import { getVariableText } from '../helper/variableText';

export function EditCancelInstruct({ page }) {
  return (
    <div className="textBackground">
      <p>
        <b>Timing:</b>
        <br />
        Booking payments are processed and "locked-in" 24 hours before the
        reserved time, so any edits or cancelations must be made{' '}
        <strong>24 hours</strong> or more prior to the reserved time.
        <br />
        For that reason, we suggest that you do not submit your reservation
        unless you are ready to commit to this 24-hour lock-in policy.
      </p>
      <p>
        <b>Access:</b>
        <br />
        To edit or cancel your reservation, log in to and use the Skedda
        account created when you originally booked a room:
        <br />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.skedda.com/en/articles/453529-i-never-set-a-password-how-do-i-login"
        >
          I never set a password...how do I login?
        </a>
      </p>
      <p>
        <b>Editing Reservations:</b>
        <br />
        To make an edit, use the instructions in the following link:
        <br />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.skedda.com/en/articles/105735-can-i-edit-a-booking"
        >
          Can I edit a booking?
        </a>
      </p>
      <p>
        <b>Canceling Reservations:</b>
        <br />
        To make a cancelation, use the instructions in the following link:
        <br />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.skedda.com/en/articles/105738-how-to-remove-delete-and-cancel-bookings"
        >
          How to remove, delete and cancel bookings
        </a>
      </p>
      <p>
        <b>Assistance:</b>
        <br />
        If you have trouble editing or canceling your reservation and would
        like additional assistance, please use the contact form and
        instructions {getVariableText(page)}, particularly the instructions
        under "Help Edit/Cancel".  If requesting assistance, please contact us
        as soon as possible before the 24-hour reservation lock-in, to give us
        time to assist you.
      </p>
    </div>
  );
}
