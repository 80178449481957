import React from 'react';
import '../../styles/style.scss';

export function FeaturesMarketing() {
  return (
    <div id="extraMarginTop" className="marketingContainer">

      <div className="featureContainer">
        <img src="knowledge.svg" />
        <div className="featureHeader">Distraction Free</div>
        <p>
          Enjoy a private space where you and your team can focus and
          innovate, without the distractions of shared coworking spaces or
          over-crowded offices.
        </p>
      </div>

      <div className="featureContainer">
        <img id="air" src="air-conditioner.svg" />
        <div className="featureHeader">Equipped for Productivity</div>
        <p>
          Air conditioning, private offices, conference rooms, and more. Each
          suite includes everything you need to perform at your best.
          Additional laptops can be provided.
        </p>
      </div>

      <div className="featureContainer">
        <img id="desk" src="work-space.svg" />
        <div className="featureHeader">Flexible Floorplans</div>
        <p>
          Feel free to rearrange the space to suit any of your meeting,
          presentation, or workspace needs. Call if you have any questions
          or concerns.
        </p>
      </div>

      <div className="featureContainer">
        <img src="glass.svg" />
        <div className="featureHeader">Transparent Pricing</div>
        <p>
          No long-term commitments or hidden fees. Pay only for the space
          you need, when you need it. See the Booking page for more details
          and our cancelation policy.
        </p>
      </div>

      <div className="featureContainer">
        <img src="chat.svg" />
        <div className="featureHeader">Here to Help</div>
        <p>
          We at TheQMRGroup are always happy to help. Send us an email or
          give us a call. Contact information can be found below.
        </p>
      </div>

      <div className="featureContainer">
        <img src="calendar.svg" />
        <div className="featureHeader">Intuitive Booking</div>
        <p>
          Book a room on the Booking page via Skedda or submit a message by our
          contact form to arrange an ongoing office rental. Navigate to the
          Booking page to learn more about pricing and options.
        </p>
      </div>

    </div>
  );
}
