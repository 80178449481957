import React from 'react';
import { BookingRules } from './content-for-booking/BookingRules';
import { Footer } from './Footer';

export function Rules() {
  return (
    <>
      <div className="contentContainer">
        <div id="legalTerms">
          <div className="openButton">
            <div className="policyTitle">Booking Rules</div>
            <BookingRules page="Rules" />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
