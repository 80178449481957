import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export function Footer({ media, setMedia }) {
  let location = useLocation();

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  //media state alteration logic

  // function handleMouseUp(e) {
  //   setWindowWidth(window.outerWidth);
  //   if (windowWidth <= 425) {
  //     setMedia('mobile');
  //   }
  //   if (windowWidth <= 899) {
  //     setMedia('tablet');
  //   } else {
  //     setMedia('desktop');
  //   }
  //   console.log('Window Width:', windowWidth);
  // }

  // function resize() {
  //   window.removeEventListener('mouseup', handleMouseUp);
  //   console.log('Resize function running');
  //   window.addEventListener('mouseup', handleMouseUp);
  // }

  function resize() {
    console.log('Resize function running');

    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(function () {
      const windowInnerWidth = window.innerWidth;
      if (windowInnerWidth <= 425) {
        setMedia('mobile');
      }
      if (windowInnerWidth <= 899) {
        setMedia('tablet');
      } else {
        setMedia('desktop');
      }
      console.log(' Ending Window Width:', windowInnerWidth);
    }, 250);
  }

  useEffect(() => {
    console.log('useEffect Running');
    resize();
    window.onresize = resize;
  }, []);

  console.log('Media', media);

  //When screen is below 639px width: alter the layout of the footer elements

  //1st Change:
  // No Menu
  // Contact Booking Powered by
  //  Attributes (long narrow row div)

  return (
    <>
      <div className="footer">
        <div className="footerTextContainer">
          <div className="footerText">
            <b>MENU</b>
            <NavLink
              to="/"
              onClick={handleClick}
              className={
                location.pathname === '/' ? 'footerMenuSelected' : 'footerMenu'
              }
            >
              Home
            </NavLink>

            <NavLink
              to="/Layout"
              onClick={handleClick}
              className={
                location.pathname === '/Layout'
                  ? 'footerMenuSelected'
                  : 'footerMenu'
              }
            >
              Layout
            </NavLink>
            <NavLink
              to="/Booking"
              onClick={handleClick}
              className={
                location.pathname === '/Booking'
                  ? 'footerMenuSelected'
                  : 'footerMenu'
              }
            >
              Booking
            </NavLink>
          </div>
          <div className="footerText">
            <b>CONTACT</b>
            <p>info@theqmrgroup.com</p>
            <p>760-349-6573</p>
            <p>Hours: 7am - 7pm</p>
          </div>
          <div className="footerText">
            <b>Booking Powered by:</b>
            <img id="skedda" src="skeddaLogo.png" />
            <img id="cards" src="acceptsCards.png" />
          </div>
          <div className="footerText">
            <b>ATTRIBUTES</b>
            <p>Icons: www.flaticon.com</p>
            <div className="artistsContainer">
              <p>Artists: Freepik, smalllikeart, Monkik, ultimatearm</p>
            </div>
            <div className="artistsContainer">
              <p>Site by:</p>&nbsp;
              <a target="_blank" href="https://www.linkedin.com/in/katxagora/">
                Katrina Kozorezov
              </a>
            </div>
          </div>
        </div>
        <div className="footerLegal">
          <div className="legalText">
            <NavLink
              to="/Rules"
              onClick={handleClick}
              id={location.pathname === '/Rules' ? 'selected' : null}
            >
              Booking Rules
            </NavLink>
            <p>|</p>
            <NavLink
              to="/Cancelation"
              onClick={handleClick}
              id={location.pathname === '/Cancelation' ? 'selected' : null}
            >
              Cancelation Policy
            </NavLink>
          </div>
          <div className="legalText">&copy; TheQMRGroup, Inc. 2020</div>
          <div className="legalText"></div>
        </div>
        <div className="peopleContainer">
          <embed id="peopleSvg" src="peopleSvg-02.svg" />
        </div>

        <div className="cleanBorder" />
      </div>

      {/* <img id="tree" src="cityScene/tree.svg" />

        <img id="buildings" src="cityScene/buildings.svg" />
        <img id="park" src="cityScene/park.svg" />

        <img id="office" src="cityScene/office.svg" />

        <img id="car" src="cityScene/eco-car.svg" />

        <img id="hotel" src="cityScene/hotel.svg" />
        <img id="welcome" src="cityScene/traffic-sign.svg" /> */}

      {/* <img id="post" src="cityScene/signpost.svg" />
        <img id="cactus" src="cityScene/cactus.svg" />
        <img id="desert" src="cityScene/desert.svg" /> */}
    </>
  );
}
