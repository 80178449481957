import React from 'react';
import { NavLink } from 'react-router-dom';

export function IntroBase({ handleClick }) {
  return (
    <div className="whatWeProvideText">
      <p className="textBackground">
        The Ridgecrest Training &amp; Conference Center is the ideal place to
        schedule your <b>staff meeting</b> or conduct <b>onboarding</b> {' '}
        and <b>training</b>.
      </p>
      <p className="textBackground">
        * Temporary office space can also be discussed and negotiated: navigate
        to the Booking page to send an inquiry via contact form.
      </p>
      <div className="centeredContainer">
        <NavLink
          to="/Booking"
          id="bookingAnchorTag"
          onClick={handleClick}
        >
          <button className="bookingButton">
            Book Now
            <div className="bookingArrow">➠</div>
          </button>
        </NavLink>
      </div>
    </div>
  );
}
